import React, { useEffect } from 'react';

import Image from '../atoms/Image';
import Paragraph from '../atoms/Paragraph';

const stylesMapper = {
  success: { bg: 'bg-semantic-successLight-50', src: '/images/toast-success.svg' },
  warning: { bg: 'bg-semantic-warningLight-50', src: '/images/toast-warning.svg' },
  error: { bg: 'bg-semantic-errorLight-50', src: '/images/toast-error.svg' },
  info: { bg: 'bg-semantic-infoLight-50', src: '/images/toast-info.svg' }
};

const Toast = ({
  body, onClose, severity = 'error', zIndex = 'z-50', top = 'top-18', noWrap = `lg:whitespace-pre  ${onClose ? 'pr-8' : ''}`,
  autoCloseInterval, cta, position = 'fixed'
}) => {
  useEffect(() => {
    if (onClose && autoCloseInterval) {
      setTimeout(() => {
        onClose();
      }, parseInt(autoCloseInterval, 10));
    }
  }, []);

  return (
    <div className={`flex justify-center ${position} transform left-1/2 -translate-x-1/2 ${top} ${zIndex} `}>
      <div className={`${stylesMapper[severity].bg} shadow-md-2 font-OpenSans rounded-md px-4 flex items-center py-3.5 relative ${noWrap}`}>
        <Image src={stylesMapper[severity].src} width={21} height={21} alt={'info'} />
        <Paragraph className="text-sm text-neutral-110 font-Inter font-semibold text-center px-2">{body}</Paragraph>
        {cta}
        {onClose && <Image src="/images/toast-close-icon.svg" onClick={onClose} width={12} height={12} alt={'close-icon'} className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer" />}
      </div>
    </div>
  );
};

export default Toast;
