import type React from 'react';
import { useEffect, useRef } from 'react';

import Img1 from '../../public/images/login/img1.png';
import Img2 from '../../public/images/login/img2.png';
import Img3 from '../../public/images/login/img3.png';
import Img4 from '../../public/images/login/img4.png';
import Img5 from '../../public/images/login/img5.png';
import Img6 from '../../public/images/login/img6.png';
import Img7 from '../../public/images/login/img7.png';
import Img8 from '../../public/images/login/img8.png';
import Img9 from '../../public/images/login/img9.png';
import Img10 from '../../public/images/login/img10.png';
import Img11 from '../../public/images/login/img11.png';
import Img12 from '../../public/images/login/img12.png';
import Img13 from '../../public/images/login/img13.png';
import Img14 from '../../public/images/login/img14.png';

const brandLogos = [
  {
    id: '0',
    src: Img1,
    alt: 'img1',
    className: 'w-[113px] min-w-[113px]'
  },
  {
    id: '1',
    src: Img2,
    alt: 'img2',
    className: 'w-[89px] min-w-[89px]'
  },
  {
    id: '2',
    src: Img3,
    alt: 'img3',
    className: 'w-[169px] min-w-[150px]'
  },
  {
    id: '3',
    src: Img4,
    alt: 'img4',
    className: 'w-[128px] min-w-[128px]'
  },
  {
    id: '4',
    src: Img9,
    alt: 'img9',
    className: 'w-[113px] min-w-[113px]'
  },
  {
    id: '5',
    src: Img10,
    alt: 'img10',
    className: 'w-[89px] min-w-[89px]'
  },
  {
    id: '6',
    src: Img11,
    alt: 'img11',
    className: 'w-[169px] min-w-[150px]'
  },
  {
    id: '7',
    src: Img12,
    alt: 'img12',
    className: 'w-[128px] min-w-[128px]'
  },
  {
    id: '8',
    src: Img13,
    alt: 'img13',
    className: 'w-[113px] min-w-[113px]'
  },
  {
    id: '9',
    src: Img14,
    alt: 'img14',
    className: 'w-[89px] min-w-[89px]'
  }
];

const avatarLogos = [
  {
    id: 'avatar1',
    src: Img5,
    alt: 'img5',
    className: 'w-[52px] min-w-[52px]'
  },
  {
    id: 'avatar2',
    src: Img6,
    alt: 'img6',
    className: 'w-[52px] min-w-[52px]'
  },
  {
    id: 'avatar3',
    src: Img7,
    alt: 'img7',
    className: 'w-[52px] min-w-[52px]'
  },
  {
    id: 'avatar4',
    src: Img8,
    alt: 'img8',
    className: 'w-[52px] min-w-[52px]'
  },
];

function ImageGrid () {
  const visibleLogos = useRef(['0', '1', '2', '3']);
  const imgRefs = useRef<HTMLImageElement[]>([]);

  useEffect(() => {
    // Change url on random image every 2s
    const interval = setInterval(changeRandomLogo, 2000);

    return () => clearInterval(interval);
  }, []);

  const changeRandomLogo = () => {
    const randomLogoNr = Math.floor(Math.random() * brandLogos.length);
    const randomImgNr = Math.floor(Math.random() * imgRefs.current.length);
    const randomImgRef = imgRefs.current[randomImgNr];

    if (visibleLogos.current.includes(randomLogoNr.toString())) {
      changeRandomLogo();
    } else {
      const visibleLogosCopy = [...visibleLogos.current];
      visibleLogosCopy[randomImgNr] = randomLogoNr.toString();
      visibleLogos.current = visibleLogosCopy;
      randomImgRef.src = brandLogos[randomLogoNr].src.src;
      randomImgRef.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 2000,
        fill: 'forwards'
      });
      randomImgRef.alt = brandLogos[randomLogoNr].alt;
    }
  };

  return (
    <ul className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-[30px] place-items-center max-w-[608px]'>
      {brandLogos.slice(0, 4).map((img, index) => (
        <li key={index}><img
          id={img.id.toString()}
          src={img.src.src}
          alt={img.alt}
          ref={ref => {
            imgRefs.current[index] = ref!;
          }}
          className='object-contain h-[52px]'
        /></li>
      ))}
      {avatarLogos.map((img, index) => (
        <li key={index}><img
          id={img.id}
          src={img.src.src}
          alt={img.alt}
          className='object-contain h-[52px]'
        /></li>

      ))}
    </ul>
  );
};

export default ImageGrid;
